.apple-nav {
  position: absolute;
  top: 120px;
  right: 0;
  left: 0;
  z-index: 9999;
  display: block;
  margin: 0;
  width: 100%;
  min-width: 1024px;
  height: 48px;
  max-height: 44px;
  background-color: #000;

  > div {
    margin: 0 auto;
    max-width: 980px;
    padding: 0 22px;
    position: relative;
    z-index: 2;
    padding-left: max(22px, env(safe-area-inset-left));
    padding-right: max(22px, env(safe-area-inset-right));

    .apple-mobile-nav-header {
      display: none;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 48px;
      overflow: hidden;
      margin-top: 0;

      li:first-child {
        left: max(0px, env(safe-area-inset-left));
        position: absolute;
        z-index: 2;
        top: 0;
        width: 48px;
        height: 48px;
        border-bottom: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        > a {
          left: 0;
          color: #fff;
          position: absolute;
          top: 0;
          width: 48px;
          height: 48px;
          padding: 0;
          z-index: 10;
        }

        .line-burger {
          position: absolute;
          z-index: 3;
          top: 9px;
          left: 9px;
          width: 30px;
          height: 30px;
          -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
          transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

          &:first-child {
            transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
            transform: none;
            z-index: 4;

            > span {
              top: 14px;
              transform: translateY(-3px) scaleX(0.88235);
              transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84)
                0.1008s;
            }
          }

          &:last-child {
            transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
            transform: none;

            > span {
              bottom: 14px;
              transform: translateY(3px) scaleX(0.88235);
              transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84)
                0.1008s;
            }
          }

          > span {
            display: block;
            width: 17px;
            height: 1px;
            background: #fff;
            border-radius: 0.5px;
            position: absolute;
            left: 7px;
            z-index: 1;
          }
        }
      }

      li:nth-child(2) {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 0;
        left: 50%;
        margin-left: -24px;
        text-align: center;
        z-index: 1;

        a {
          width: 17px;
          background-size: 17px 48px;
          height: 48px;
        }
      }

      li:nth-child(3) {
        position: absolute;
        top: 0;
        width: auto;
        height: 48px;
        right: max(0px, env(safe-area-inset-right));
        display: block;
        visibility: visible;
        transition: transform 0.44s 0.2s cubic-bezier(0.04, 0.04, 0.12, 0.96),
          opacity 0.28s 0.36s cubic-bezier(0.52, 0.16, 0.24, 1);

        a {
          padding: 0 16px;
          width: 15px;
          height: 48px;
          background-size: 15px 48px;
        }
      }
    }

    .apple-nav-list {
      cursor: default;
      margin: 0 -8px;
      width: auto;
      height: 44px;
      display: flex;
      justify-content: space-between;
      user-select: none;
    }

    li {
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      height: 44px;
      z-index: 1;
      vertical-align: top;

      > a {
        font-size: 12px;
        line-height: 3.66667;
        font-weight: 400;
        letter-spacing: -0.01em;
        font-family: 'SF Pro Text', 'Myriad Set Pro', 'SF Pro Icons',
          'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
        color: #f5f5f7;
        position: relative;
        z-index: 1;
        display: inline-block;
        padding: 0 8px;
        height: 44px;
        opacity: 0.8;
        background: no-repeat;
        text-decoration: none;
        white-space: nowrap;
        transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline-offset: -7px;

        &.logo {
          background-size: 14px 44px;
          background-repeat: no-repeat;
          background-image: url('../assets/logo.svg');
          background-position: center center;
          width: 14px;
        }

        &.search {
          background-size: 15px 88px;
          background-repeat: no-repeat;
          background-image: url('../assets/search.svg');
          background-position: 8px 0;
          width: 15px;
        }

        &.bag {
          background-size: 13px 44px;
          background-repeat: no-repeat;
          background-image: url('../assets/bag.svg');
          background-position: center center;
          width: 13px;
        }

        &:hover {
          opacity: 1;
        }

        > span {
          font-size: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1044px) {
  .apple-nav {
    min-width: 320px;
  }
}

@media only screen and (max-width: 833px) {
  .apple-nav {
    overflow-y: hidden;
    max-height: none;

    > div {
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      .apple-mobile-nav-header {
        display: block;
      }
      .apple-nav-list {
        display: none;
      }
    }
  }
}
