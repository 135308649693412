.subsection-m1-pro {
  --colors: 5;
  --angle: 45;
  --blend: 0.5;
  --color1: #252fff;
  --color2: #256af3;
  --color3: #7cc0e2;
  --color4: #256af3;
  --color5: #252fff;
  --fade1: 0%;
  --fade2: 50%;
  --fade3: 100%;
  --fade4: 150%;
  --fade5: 200%;

  position: relative;
  z-index: 2;
  overflow: hidden;
}

.subsection-m1-max {
  --colors: 5;
  --angle: 45;
  --blend: 0.5;
  --color1: #8727ff;
  --color2: #9d44fd;
  --color3: #ff9fe1;
  --color4: #9d44fd;
  --color5: #8727ff;
  --fade1: 0%;
  --fade2: 50%;
  --fade3: 100%;
  --fade4: 150%;
  --fade5: 200%;

  overflow: hidden;
  padding-top: 160px;
}

.section-performance {
  .subsection-performance-headline {
    display: inline-block;
    background-image: linear-gradient(
      45deg,
      var(--color1) var(--fade1),
      var(--color2) var(--fade2),
      var(--color3) var(--fade3),
      var(--color4) var(--fade4),
      var(--color5) var(--fade5)
    );
    color: var(--color2);
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-clip: text;
    padding-bottom: 5px;
    margin-bottom: -5px;
    margin-top: 4px;
  }
}

.section-pad-top {
  padding-top: 200px;
}

.theme-dark {
  .section-eyebrow {
    color: #f5f5f7;
  }
}

.typography-tout {
  font-size: 19px;
  line-height: 1.2105263158;
  font-weight: 600;
  letter-spacing: 0.012em;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.typography-section-headline-reduced {
  font-size: 56px;
  line-height: 1.0714285714;
  font-weight: 600;
  letter-spacing: -0.005em;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.typography-performance-section-copy {
  font-size: 24px;
  line-height: 1.1666666667;
  font-weight: 600;
  letter-spacing: 0.009em;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 1068px) {
  .subsection-m1-pro {
    padding-top: 145px;
  }

  .subsection-m1-max {
    padding-top: 113px;
  }

  .typography-section-headline-reduced {
    font-size: 48px;
    line-height: 1.0834933333;
    letter-spacing: -0.003em;
  }

  .typography-performance-section-copy {
    font-size: 21px;
    line-height: 1.1904761905;
    letter-spacing: 0.011em;
  }
}

@media only screen and (max-width: 1068px) {
  .subsection-m1-pro {
    padding-top: 97px;
  }

  .subsection-m1-max {
    padding-top: 92px;
  }

  .typography-section-headline-reduced {
    font-size: 40px;
    line-height: 1.1;
    letter-spacing: 0em;
  }

  .typography-performance-section-copy {
    font-size: 17px;
    line-height: 1.2353641176;
    letter-spacing: -0.022em;
  }
}
