.subsection-even-more-tiles {
    .tile {
        background-color: #151516;
        max-height: 770px;
    }

    .even-more-tiles {
        .tile-headline span {
            color: #f5f5f7;
        }

        .tile-coolest .tile-image {
            display: block;
            width: var(--p-width);
            height: var(--p-height);
            --p-width: 560px;
            --p-height: 542px;
            display: flex;
            align-self: flex-end;
            margin-top: 9px;
        }
        .tile-fast .tile-image {
            display: block;
            width: var(--p-width);
            height: var(--p-height);
            --p-width: 426px;
            --p-height: 172px;
            display: flex;
            align-self: center;
            align-items: flex-start;
            margin-bottom: auto;
        }
        .tile-fast .tile-image--up-to {
            display: block;
            width: var(--p-width);
            height: var(--p-height);
            --p-width: 426px;
            --p-height: 240px;
        }
        .tile-fast .tile-headline {
            margin-bottom: auto;
        }
    }
}

.section-content-tiles {
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
}

.tiles {
    margin-left: 20px;
    margin-right: 20px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-top: 142px;
    padding-left: 0;
    padding-right: 0;

    .grid-item {
        display: flex;
    }
}

.large-span-6 {
    grid-column: span 6;
}

.grid-item {
    position: relative;
    box-sizing: border-box;
}

.tile {
    display: flex;
    flex-direction: column;
    background-color: #f5f5f7;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.tile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 50px 0 0;
    flex: 1;
}

.tile-headline {
    color: #a1a1a6;
    padding: 0 45px;
    z-index: 1;
    align-self: flex-start;
}

.typography-bento-copy {
    font-size: 24px;
    line-height: 1.1666666667;
    font-weight: 600;
    letter-spacing: 0.009em;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.tile-image {
    display: flex;
    margin-top: 60px;
    z-index: 0;
}

@media only screen and (max-width: 1068px) {
    .section-content-tiles {
        max-width: 768px;
    }

    .tiles {
        margin-top: 125px;
    }

    .subsection-even-more-tiles {
        .even-more-tiles {
            .tile-coolest .tile-image {
                margin-top: 46px;
            }

            .tile-coolest .tile-image {
                --p-width: 344px;
                --p-height: 314px;
            }

            .tile-fast .tile-image--up-to {
                margin-top: 17px;
            }

            .tile-fast .tile-image--up-to {
                --p-width: 264px;
                --p-height: 150px;
            }
        }
    }

    .typography-bento-copy {
        font-size: 21px;
        line-height: 1.1904761905;
        font-weight: 600;
        letter-spacing: 0.011em;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    }
}

@media only screen and (max-width: 734px) {
    .tiles {
        margin-top: 100px;

        .grid-item {
            justify-content: center;
        }
    }

    .small-span-12 {
        grid-column: span 12;
    }

    .tile {
        max-width: 400px;
        min-height: 459px;
    }

    .tile-headline {
        padding: 0 34px;
    }

    .subsection-even-more-tiles {
        .even-more-tiles {
            .tile-coolest .tile-image {
                margin-top: 36px;
            }

            .tile-coolest .tile-image {
                --p-width: 346px;
                --p-height: 316px;
            }

            .tile-fast .tile-image--up-to {
                margin-top: 63px;
                margin-bottom: 50px;
            }

            .tile-fast .tile-image--up-to {
                --p-width: 244px;
                --p-height: 140px;
            }
        }
    }

    .typography-bento-copy {
        font-size: 19px;
        line-height: 1.2105263158;
        font-weight: 600;
        letter-spacing: 0.012em;
        font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    }
}
