body {
  font-size: 17px;
  line-height: 1.4705882353;
  font-weight: 400;
  letter-spacing: -0.022em;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: #fff;
  color: #1d1d1f;
  font-style: normal;
}

body, button, input, select, textarea {
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: ltr;
  text-align: left;
}

abbr, blockquote, body, button, dd, dl, dt, fieldset, figure, form, h1, h2, h3, h4, h5, h6, hgroup, input, legend, li, ol, p, pre, ul {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

h2+*, h3+*, h4+*, h5+*, h6+* {
  margin-top: 0.8em;
}

ol+*, p+*, ul+* {
  margin-top: 0.8em;
}