.subsection-configuration {
    .configuration-headline {
        color: #f5f5f7;
    }

    .hardware-lockup {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        position: relative;

        img {
            opacity: 0.15;
        }

        .figcaption-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-top: 18px;
        }
    }

    .configuration-copy {
        padding-top: 42px;
    }

    .cpu-gallery-gallery {
        margin-top: 80px;
    }
}

@media only screen and (max-width: 1068px) {
    .subsection-configuration {
        padding-top: 113px;

        .hardware-lockup {
            margin-top: 70px;
        }

        .configuration-copy {
            padding-top: 34px;
        }
    }
}

@media only screen and (max-width: 734px) {
    .subsection-configuration {
        .cpu-gallery-gallery {
            margin-top: 80px;
        }
    }
}
