.theme-dark {
  background-color: #000;
  color: #a1a1a6;

  .footnote-number {
    a {
      &:hover {
        color: #2997ff;
      }
    }
  }
}

.section-content {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
}

.footnote-number {
  top: auto;
  position: relative;
  vertical-align: initial;
  font-feature-settings: "numr";
  font-size: 1em;

  a {
    vertical-align: inherit;
    color: inherit;

    &:hover {
      color: "#06c";
    }
  }
}

.grid {
  margin-left: auto;
  margin-right: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.column {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.badge {
  margin-right: 2em;
  box-sizing: border-box;
  display: inline-block;
  font-size: 20px;
  min-width: 5em;
  position: relative;
  z-index: 1;
  color: #f5f5f7;

  .badge-content {
    margin: 1em 0;
    padding-top: 4px;
    padding-bottom: 2px;
    position: relative;
    z-index: 1;
  }

  .badge-caption {
    left: 0;
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;

    color: #f5f5f7;
    font-size: 17px;
    line-height: 1.2353641176;
    font-weight: 400;
    letter-spacing: -0.022em;
    font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;

    &:first-child {
      top: auto;
      bottom: 100%;
    }
  }

  .badge-unit {
    font-size: 40px;
    line-height: 1.1;
    font-weight: 400;
    letter-spacing: 0em;
    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 600;
  }
}

.large-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.large-5 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.large-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.large-7 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.large-10 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.large-offset-2 {
  margin-left: 16.6666666667%;
}

@media only screen and (max-width: 1068px) {
  .section-content {
    max-width: 580px;
  }
  .medium-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .medium-offset-0 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 734px) {
  .section-content {
    max-width: 400px;
    width: 87.5%;
  }
  .small-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .small-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .small-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
}
