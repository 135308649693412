.section-content-stats {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;

  .subsection-performance-stats {
    display: block;
    position: relative;
    max-width: 1400px;
    height: 900px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100vw - 40px);
    margin-top: 85px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 2;
    }

    .inline-media {
      position: relative;
      height: 900px;

      video {
        z-index: 1;
        display: block;
        left: 0;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .subsection-performance-stats-copy {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    color: #f5f5f7;
    margin-top: 0;
    z-index: 2;

    .subsection-performance-stat {
      margin-right: 20px;
      padding-bottom: 10px;

      .stat-border {
        display: block;
        width: 40px;
        height: 4px;
        background-color: #f5f5f7;
        margin-bottom: 15px;
        transform-origin: center left;
      }

      .stat-value {
        display: block;
        opacity: 0;
      }
    }

    p {
      position: absolute;
      overflow: hidden;

      &:first-child {
        top: 10.2%;
        left: 55.8%;
      }

      &:nth-child(2) {
        top: 23.6%;
        left: 13.8%;
      }

      &:nth-child(3) {
        top: 36.3%;
        left: 67%;
      }

      &:nth-child(4) {
        top: 56.7%;
        left: 7.4%;
      }

      &:nth-child(5) {
        top: 72.4%;
        left: 46.6%;
      }
    }
  }
}

.typography-performance-section-stats {
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 0em;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 1068px) {
  .section-content-stats {
    max-width: 100%;

    .subsection-performance-stats {
      height: 574px;
      max-width: 1068px;

      .inline-media {
        height: 574px;
      }
    }

    .subsection-performance-stats-copy {
      p {
        &:first-child {
          top: 14.3%;
          left: 58%;
        }

        &:nth-child(2) {
          top: 19.5%;
          left: 12.6%;
        }

        &:nth-child(3) {
          top: 39%;
          left: 64%;
        }

        &:nth-child(4) {
          top: 55.7%;
          left: 5.8%;
        }

        &:nth-child(5) {
          top: 65%;
          left: 47%;
        }
      }
    }
  }

  .typography-performance-section-stats {
    font-size: 24px;
    line-height: 1.1666666667;
    letter-spacing: 0.009em;
  }
}

@media only screen and (max-width: 734px) {
  .section-content-stats {
    .subsection-performance-stats {
      height: 560px;
      max-width: 736px;

      .inline-media {
        height: 560px;
      }
    }

    .subsection-performance-stats-copy {
      p {
        &:first-child {
          top: 7.6%;
          left: 58%;
        }

        &:nth-child(2) {
          top: 22.2%;
          left: 5%;
        }

        &:nth-child(3) {
          top: 36%;
          left: 58%;
        }

        &:nth-child(4) {
          top: 51.3%;
          left: 5%;
        }

        &:nth-child(5) {
          top: 69.7%;
          left: 58%;
        }
      }
    }
  }

  .typography-performance-section-stats {
    font-size: 21px;
    line-height: 1.1904761905;
    letter-spacing: 0.011em;
  }
}
