.macbook-nav {
  position: sticky;
  top: 0;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1024px;
  height: 66px;
  z-index: 9997;
  margin-bottom: -110px;
  margin-top: 44px;

  .mn-hr {
    margin-left: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.16);
    z-index: 1;
  }

  .mn-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 980px;
    padding-left: calc(max(22px, env(safe-area-inset-left)));
    padding-right: calc(max(22px, env(safe-area-inset-right)));
    z-index: 2;

    .mn-title {
      font-size: 21px;
      line-height: 1.14286;
      font-weight: 600;
      letter-spacing: 0.011em;
      font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue',
        'Helvetica', 'Arial', sans-serif;
      height: 52px;
      color: #000;
      transition: color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      white-space: nowrap;
      opacity: 0.88;

      span {
        font-size: 12px;
        line-height: 1.33337;
        font-weight: 400;
        letter-spacing: -0.01em;
        font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue',
          'Helvetica', 'Arial', sans-serif;
        position: relative;
        top: -1px;
        opacity: 0.64;
      }
    }

    > div {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 1;
      font-weight: 400;
      letter-spacing: -0.01em;
      font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
        'Arial', sans-serif;
      margin-top: -3px;

      .mn-menu {
        display: flex;
        padding-top: 34px;

        li {
          margin-left: 24px;

          a {
            color: #000;
            display: inline-block;
            line-height: 22px;
            white-space: nowrap;
            opacity: 0.88;
          }

          &:hover:not(:first-child) {
            a {
              color: #06c !important;
              opacity: 1;
            }
          }

          &:first-child {
            a {
              opacity: 0.56;
              cursor: default;
            }
          }
        }
      }

      .mn-actions {
        display: flex;
        align-items: center;
        margin-top: 0;
        padding-top: 34px;

        .mn-action-menu-cta {
          display: none;
          margin-top: 3px;
          margin-bottom: -7px;
          position: relative;
          z-index: 1;
          margin-left: 24px;
          margin-right: -11px;
          cursor: pointer;
          overflow: hidden;
          width: 40px;
          height: 30px;
          -webkit-tap-highlight-color: transparent;

          .chevron {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1),
              transform-origin 1s cubic-bezier(0.86, 0, 0.07, 1);
            transform: translateY(0);
            opacity: 0.8;

            &:hover {
              opacity: 1;
            }

            &:before,
            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 13px;
              width: 11px;
              height: 1px;
              z-index: 1;
              transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1),
                transform-origin 1s cubic-bezier(0.86, 0, 0.07, 1);
              background: #000;
            }

            &:before {
              transform-origin: 100% 100%;
              transform: rotate(40deg) scaleY(1.1);
              right: 50%;
              border-radius: 0.5px 0 0 0.5px;
            }

            &:after {
              transform-origin: 0% 100%;
              transform: rotate(-40deg) scaleY(1.1);
              left: 50%;
              border-radius: 0 0.5px 0.5px 0;
            }
          }
        }

        a {
          margin-left: 24px;
          cursor: pointer;
          display: inline-block;
          text-align: center;
          white-space: nowrap;
          background: #0071e3;
          color: #fff !important;
          border-radius: 980px;
          line-height: 1.33337;
          min-width: 23px;
          padding-left: 11px;
          padding-right: 11px;
          padding-top: 4px;
          padding-bottom: 4px;

          &:hover {
            background: #147ce5;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1044px) {
  .macbook-nav {
    min-width: 320px;
  }
}

@media only screen and (max-width: 833px) {
  .macbook-nav {
    margin-top: 48px;
    margin-bottom: -96px;

    .mn-content {
      > div {
        .mn-menu {
          display: none;
        }

        .mn-actions {
          padding-left: calc(max(16px, env(safe-area-inset-left)));

          .mn-action-menu-cta {
            display: block;
          }

          a {
            margin-left: 17px;
            margin-top: -3px;
          }
        }
      }
    }
  }
}
